import axios from "axios";
import Cookies from "js-cookie";

const AuthUser = () => {
  const BASE_URL = "https://cecy-reading-planner.atreuslab.com/public/api/v1";

  const http = axios.create({
    baseURL: BASE_URL,
  });

  http.interceptors.request.use(
    (config) => {
      const token = getToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const setToken = (token) => {
    Cookies.set("token", token, { expires: 7 }); // Vence en 7 días
  };

  const getToken = () => {
    return Cookies.get("token");
  };

  function isLoggedIn() {
    const token = getToken();
    return !!token;
  }

  function logOut() {
    Cookies.remove("token");
  }

  return {
    http,
    setToken,
    isLoggedIn,
    logOut,
    getToken,
  };
};

export default AuthUser;
