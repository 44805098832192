import React, { Component } from "react";
import "./styles/AddPlanForm.css";

class AddPlanForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name_book: "",
      cover: null,
      author: "",
      selectedGenre: "", // Almacenar el género seleccionado
      page_in: 0,
      page_end: 0,
      date_in: "",
      date_end: "",
      pages_per_day: 0,
      uid: "",
      active: 1,
      status: 1,
      coverPreview: null,
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleImageChange = (event) => {
    const file = event.target.files[0];
    this.setState({ cover: file });

    // Crear una URL de objeto para previsualizar la imagen
    const reader = new FileReader();
    reader.onload = (e) => {
      this.setState({ coverPreview: e.target.result });
    };
    reader.readAsDataURL(file);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    // Aquí puedes agregar la lógica para crear el plan de lectura,
    // incluyendo la manipulación de la imagen seleccionada (this.state.cover).
    // Luego, puedes enviar estos valores al servidor para agregar el plan a la base de datos.
    // Después de agregar el plan, puedes restablecer el estado o cerrar el formulario, según lo que desees hacer.
  };

  render() {
    return (
      <div className="add-plan-form">
        <h2>Agregar Plan de Lectura</h2>
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label htmlFor="name_book">Título del Libro:</label>
            <input
              type="text"
              id="name_book"
              name="name_book"
              value={this.state.name_book}
              onChange={this.handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="cover">Seleccionar Portada:</label>
            <input
              type="file"
              id="cover"
              name="cover"
              accept="image/*"
              onChange={this.handleImageChange}
              required
            />
          </div>
          {/* Previsualización de la imagen */}
          {this.state.coverPreview && (
            <div className="form-group cover-preview-container">
              <label>Previsualización:</label>
              <img
                src={this.state.coverPreview}
                alt="Previsualización de la portada"
                className="cover-preview"
              />
            </div>
          )}
          <div className="form-group">
            <label htmlFor="author">Autor del Libro:</label>
            <input
              type="text"
              id="author"
              name="author"
              value={this.state.author}
              onChange={this.handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="id_gender">Género:</label>
            <input
              type="text"
              id="id_gender"
              name="id_gender"
              value={this.state.id_gender}
              onChange={this.handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="page_in">Página de Inicio:</label>
            <input
              type="number"
              id="page_in"
              name="page_in"
              value={this.state.page_in}
              onChange={this.handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="page_end">Página de Fin:</label>
            <input
              type="number"
              id="page_end"
              name="page_end"
              value={this.state.page_end}
              onChange={this.handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="date_in">Fecha de Inicio:</label>
            <input
              type="date"
              id="date_in"
              name="date_in"
              value={this.state.date_in}
              onChange={this.handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="date_end">Fecha de Fin:</label>
            <input
              type="date"
              id="date_end"
              name="date_end"
              value={this.state.date_end}
              onChange={this.handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="pages_per_day">Páginas por Día:</label>
            <input
              type="number"
              id="pages_per_day"
              name="pages_per_day"
              value={this.state.pages_per_day}
              onChange={this.handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="uid">UID del Usuario:</label>
            <input
              type="text"
              id="uid"
              name="uid"
              value={this.state.uid}
              onChange={this.handleInputChange}
              required
            />
          </div>
          <div className="form-buttons">
            <button type="submit">Guardar</button>
            <button type="button" onClick={this.props.onCancel}>
              Cancelar
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default AddPlanForm;
