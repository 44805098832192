import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthUser from "./AuthUser";
import Bookshelf from "./Bookshelf";
import Navbar from "./Navbar";
import "./styles/Dashboard.css";

function Dashboard() {
  const navigate = useNavigate();
  const { isLoggedIn, logOut } = AuthUser();
  const [loggedIn, setLoggedIn] = useState(isLoggedIn());
  const starCount = 70;

  useEffect(() => {
    if (!loggedIn) {
      navigate("/login");
    }
  }, [loggedIn, navigate]);

  return (
    <div className="Dashboard">
      <div className="full-screen-background">
        {[...Array(starCount)].map((_, index) => (
          <div
            key={index}
            className="stars"
            style={{
              animationDelay: `${Math.random() * 4.5}s`,
              top: `${Math.floor(Math.random() * 100)}%`,
              left: `${Math.floor(Math.random() * 100)}%`,
            }}
          />
        ))}
      </div>

      <Bookshelf />
      <Navbar logOut={logOut} setLoggedIn={setLoggedIn} />
    </div>
  );
}

export default Dashboard;
