import React from "react";
import { useLocation } from "react-router-dom";
import "./styles/Navbar.css";
function Navbar({ logOut, setLoggedIn }) {
  const location = useLocation();

  const handleLogout = () => {
    logOut();
    setLoggedIn(false);
  };

  return (
    <div className="navbar">
      <i
        className={`fas fa-book navbar-icon ${
          location.pathname === "/dashboard" ? "navbar-selected" : ""
        }`}
      ></i>
      <i
        className={`fas fa-users navbar-icon ${
          location.pathname === "/users" ? "navbar-selected" : ""
        }`}
      ></i>
      <i
        className={`fas fa-user navbar-icon ${
          location.pathname === "/profile" ? "navbar-selected" : ""
        }`}
      ></i>
      <i className="fas fa-sign-out-alt navbar-icon" onClick={handleLogout}></i>
    </div>
  );
}

export default Navbar;
