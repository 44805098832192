let eventGuid = 0;
let todayStr = new Date().toISOString().replace(/T.*$/, ""); // YYYY-MM-DD of today

export const INITIAL_EVENTS = [
  { id: createEventId(), start: "2022-12-26", title: "15-24" },
  { id: createEventId(), start: "2022-12-27", title: "25-34" },
  { id: createEventId(), start: "2022-12-28", title: "35-44" },
  { id: createEventId(), start: "2022-12-29", title: "45-54" },
  { id: createEventId(), start: "2022-12-30", title: "55-64" },
  { id: createEventId(), start: "2022-12-31", title: "65-74" },
  { id: createEventId(), start: "2023-01-01", title: "75-84" },
  { id: createEventId(), start: "2023-01-02", title: "85-94" },
  { id: createEventId(), start: "2023-01-03", title: "95-104" },
  { id: createEventId(), start: "2023-01-04", title: "105-114" },
  { id: createEventId(), start: "2023-01-05", title: "115-124" },
  { id: createEventId(), start: "2023-01-06", title: "125-134" },
  { id: createEventId(), start: "2023-01-07", title: "135-144" },
  { id: createEventId(), start: "2023-01-08", title: "145-154" },
  { id: createEventId(), start: "2023-01-09", title: "155-164" },
  { id: createEventId(), start: "2023-01-10", title: "165-174" },
  { id: createEventId(), start: "2023-01-11", title: "175-184" },
  { id: createEventId(), start: "2023-01-12", title: "185-194" },
  { id: createEventId(), start: "2023-01-13", title: "195-204" },
  { id: createEventId(), start: "2023-01-14", title: "205-214" },
  { id: createEventId(), start: "2023-01-15", title: "215-224" },
  { id: createEventId(), start: "2023-01-16", title: "225-234" },
  { id: createEventId(), start: "2023-01-17", title: "235-244" },
  { id: createEventId(), start: "2023-01-18", title: "245-254" },
  { id: createEventId(), start: "2023-01-19", title: "255-264" },
  { id: createEventId(), start: "2023-01-20", title: "265-274" },
  { id: createEventId(), start: "2023-01-21", title: "275-284" },
  { id: createEventId(), start: "2023-01-22", title: "285-294" },
  { id: createEventId(), start: "2023-01-23", title: "295-304" },
  { id: createEventId(), start: "2023-01-24", title: "305-314" },
  { id: createEventId(), start: "2023-01-25", title: "315-324" },
  { id: createEventId(), start: "2023-01-26", title: "325-334" },
  { id: createEventId(), start: "2023-01-27", title: "335-344" },
  { id: createEventId(), start: "2023-01-28", title: "345-354" },
  { id: createEventId(), start: "2023-01-29", title: "355-364" },
  { id: createEventId(), start: "2023-01-30", title: "365-374" },
  { id: createEventId(), start: "2023-01-31", title: "375-384" },
  { id: createEventId(), start: "2023-02-01", title: "385-394" },
  { id: createEventId(), start: "2023-02-02", title: "395-404" },
  { id: createEventId(), start: "2023-02-03", title: "405-414" },
  { id: createEventId(), start: "2023-02-04", title: "415-424" },
  { id: createEventId(), start: "2023-02-05", title: "425-434" },
  { id: createEventId(), start: "2023-02-06", title: "435-444" },
  { id: createEventId(), start: "2023-02-07", title: "445-454" },
  { id: createEventId(), start: "2023-02-08", title: "455-464" },
  { id: createEventId(), start: "2023-02-09", title: "465-474" },
  { id: createEventId(), start: "2023-02-10", title: "475-484" },
  { id: createEventId(), start: "2023-02-11", title: "485-494" },
  { id: createEventId(), start: "2023-02-12", title: "495-504" },
  { id: createEventId(), start: "2023-02-13", title: "505-514" },
  { id: createEventId(), start: "2023-02-14", title: "515-524" },
  { id: createEventId(), start: "2023-02-15", title: "525-534" },
  { id: createEventId(), start: "2023-02-16", title: "535-544" },
  { id: createEventId(), start: "2023-02-17", title: "545-554" },
  { id: createEventId(), start: "2023-02-18", title: "555-564" },
  { id: createEventId(), start: "2023-02-19", title: "565-574" },
];

export function createEventId() {
  return String(eventGuid++);
}
