import React from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import { format, startOfWeek, getDay, parse } from "date-fns";
import { createEventId } from "./event-utils";
import "./styles/Plan.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

const locales = {
  "en-US": require("date-fns/locale/en-US"),
  "es-ES": require("date-fns/locale/es"),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

class Plan extends React.Component {
  state = {
    events: [],
  };

  componentDidMount() {
    this.generateEvents();
  }

  generateEvents = () => {
    const events = [];
    const { book } = this.props;

    let currentDate = new Date(
      parseInt(book.date_start.split("-")[2], 10),
      parseInt(book.date_start.split("-")[1], 10) - 1,
      parseInt(book.date_start.split("-")[0], 10)
    );

    for (let i = book.page_in; i < book.page_end; i += book.pages_per_days) {
      const dateString = `${currentDate.getFullYear()}-${(
        currentDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${currentDate
        .getDate()
        .toString()
        .padStart(2, "0")}`;

      if (
        ![
          "2023-04-06",
          "2023-04-07",
          "2023-04-08",
          "2023-04-09",
          "2023-04-10",
        ].includes(dateString)
      ) {
        // Evento para el porcentaje
        events.push({
          id: createEventId(),
          start: parse(dateString, "yyyy-MM-dd", new Date()),
          end: parse(dateString, "yyyy-MM-dd", new Date()),
          title: `${(
            (((i + (book.pages_per_days - 1) > book.page_end
              ? book.page_end
              : i + book.pages_per_days - 1) -
              8) /
              (book.page_end - (book.pages_per_days - 2))) *
            100
          ).toFixed(0)}%`,
        });

        // Evento para el rango de páginas
        events.push({
          id: createEventId(),
          start: parse(dateString, "yyyy-MM-dd", new Date()),
          end: parse(dateString, "yyyy-MM-dd", new Date()),
          title: `${i}-${
            i + (book.pages_per_days - 1) > book.page_end
              ? book.page_end
              : i + book.pages_per_days - 1
          }`,
        });
      } else {
        i -= book.pages_per_days;
      }

      currentDate = this.addDays(currentDate, 1);
    }

    this.setState({ events });
  };

  addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  render() {
    const { book } = this.props;

    if (!book) {
      return <div>No se ha seleccionado ningún libro.</div>;
    }

    return (
      <div className="PlanContainer">
        <div className="PlanCard">
          <div className="PlanHeader">
            <div className="PlanIDCard">
              <div className="PlanIDImageContainer">
                <img className="PlanIDImage" alt="" src={book.cover} />
              </div>
              <div className="PlanIDText">
                <h1 className="PlanTitle">{book.title}</h1>
                <div className="PlanAuthorContainer">
                  <div className="PlanAuthor">
                    <strong className="PlanAuthorName">{book.author}</strong>
                  </div>
                  <div className="PlanAuthor">
                    <strong className="PlanAuthorName">{book.members}</strong>
                  </div>
                </div>
                <p className="PlanDescription">{book.description}</p>
              </div>
            </div>
          </div>
          <Calendar
            localizer={localizer}
            events={this.state.events}
            startAccessor="start"
            endAccessor="end"
          />
        </div>
      </div>
    );
  }
}

export default Plan;
