import React from "react";
import { Link } from "react-router-dom";
import Plan from "./Plan";
import AddPlanForm from "./AddPlanForm";
import "./styles/Bookshelf.css";
import "./styles/stars.css";

class Bookshelf extends React.Component {
  state = {
    books: [
      // {
      //   code: "wday",
      //   title: "FELIZ DÍA Cecy",
      //   author: "❤️",
      //   cover: "",
      // },
      {
        code: "el_profesor",
        title: "El profesor",
        author: "John Katzenbach",
        members: "Cecy - eus",
        status: "reading",
        cover: "https://imag.lecturalia.com/img/libro/el-profesor-50534.jpg",
        date_start: "29-09-2023",
        page_in: 6,
        page_end: 510,
        pages_per_days: 10,
      },
      {
        code: "nada",
        title: "Nada",
        author: "Janne Teller",
        members: "Cecy - eus",
        status: "read",
        cover:
          "https://images.cdn2.buscalibre.com/fit-in/360x360/d6/2c/d62c3af5e8d4a59b0e2e9e6d5ec6c167.jpg",
        date_start: "21-08-2023",
        page_in: 7,
        page_end: 117,
        pages_per_days: 7,
      },
      {
        code: "ingenierodescalzo",
        title: "El ingeniero descalso",
        author: "Alfonso Gumucio Dagron",
        members: "Cecy - eus",
        status: "read",
        cover:
          "https://cdn.discordapp.com/attachments/1008571069797507102/1086457437449617449/El-ingeniero-descalzo---tapa.jpg",
        date_start: "23-03-2023",
        age_in: 13,
        page_end: 190,
        pages_per_days: 7,
      },
      {
        code: "elvis",
        title: "Elvis nunca se equivoca",
        author: "Rodrigo Morlesin",
        members: "Cecy - eus",
        status: "read",
        cover:
          "https://storage.googleapis.com/catalogo-libros/extralarge/7069396f-bf8c-3ba8-f0ce-5d1e4b2668d8_imagen.jpg",
        date_start: "11-03-2023",
        age_in: 7,
        page_end: 110,
        pages_per_days: 7,
      },
      {
        code: "gratitudes",
        title: "Gratitudes",
        author: " Delphine de Vigan",
        members: "Cecy - eus",
        status: "read",
        cover:
          "https://1.bp.blogspot.com/-E6IbQefiz2k/YJhXTHkvNHI/AAAAAAAAf-Y/KFVGpAHEulstIX2f1-sQu40SCjh6xRD1ACLcBGAsYHQ/s629/LasGratitudesv2.jpg",
        date_start: "25-02-2023",
        age_in: 8,
        page_end: 130,
        pages_per_days: 10,
      },
      {
        code: "el_mundo_de_sofia",
        title: "El mundo de sofia",
        author: "Jostein Gaarder",
        members: "Cecy - eus",
        status: "read",
        cover:
          "https://www.eltemplodelasmilpuertas.com/biblioteca/portadas/0elmundodesofia.jpg",
        date_start: "26-12-2022",
        age_in: 15,
        page_end: 574,
        pages_per_days: 10,
      },
    ],
    selectedBook: null,
    isAddingPlan: false,
    selectedScreen: "book_list", // Agregamos un estado para controlar qué pantalla se muestra
  };

  openBook = (book) => {
    this.setState({ selectedBook: book, selectedScreen: "plan" });
  };

  goBackToList = () => {
    this.setState({ selectedBook: null, selectedScreen: "book_list" });
  };

  // Función para mostrar el formulario
  showAddPlanForm = () => {
    this.setState({ isAddingPlan: true, selectedScreen: "add_plan" });
  };

  // Función para ocultar el formulario y volver a la lista de libros
  hideAddPlanForm = () => {
    this.setState({ isAddingPlan: false, selectedScreen: "book_list" });
  };

  renderBooksByStatus = (status) => {
    return this.state.books
      .filter((book) => book.status === status)
      .map((book, index) => (
        <div key={index} className="book-item">
          {/* Agrega un enlace al componente Plan con el código del libro en la URL */}
          <Link to="#">
            <img
              className="book-image"
              src={book.cover}
              alt={book.title}
              onClick={() => this.openBook(book)}
            />
          </Link>
          <div className="book-info">
            <p className="book-title">{book.title}</p>
            <p className="book-author">Autor: {book.author}</p>
            <p className="book-members">Integrantes: {book.members}</p>
          </div>
        </div>
      ));
  };

  render() {
    const { selectedBook, selectedScreen } = this.state;

    return (
      <div className="book-list">
        {/* Mostrar el botón de retroceso en la barra de navegación */}
        {selectedBook && (
          <button className="back-button" onClick={this.goBackToList}>
            Volver a la lista
          </button>
        )}

        {selectedScreen === "plan" && <Plan book={selectedBook} />}
        {selectedScreen === "add_plan" && (
          <AddPlanForm onCancel={this.hideAddPlanForm} />
        )}
        {selectedScreen === "book_list" && (
          <div>
            {/* Agregar el botón "Agregar Plan de Lectura" */}
            <button className="add-plan-button" onClick={this.showAddPlanForm}>
              Agregar Plan de Lectura
            </button>
            <h2 className="book-list-title">Leyendo</h2>
            <div className="books-container">
              {this.renderBooksByStatus("reading")}
            </div>
            <h2 className="book-list-title">Leídos</h2>
            <div className="books-container">
              {this.renderBooksByStatus("read")}
            </div>
            <h2 className="book-list-title">Abandonados</h2>
            <div className="books-container">
              {this.renderBooksByStatus("abandoned")}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Bookshelf;
